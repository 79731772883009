import { Link } from 'gatsby';
import * as React from 'react';

const NotFound: React.VFC = () => {
  return (
    <div>
      <h1>Page not found</h1>
      <Link to="/">back to top</Link>
    </div>
  );
};

export default NotFound;
