import * as React from 'react';
import Layout from '../components/layout';
import NotFound from '../components/notfound';

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle={'404'} description="" hideProfile>
      <NotFound />
    </Layout>
  );
};

export default NotFoundPage;
